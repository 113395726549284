import React from 'react';
import DefaultLayout from '../../../layouts/DefaultLayout';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../../layouts/TemplateColumn';
import { PageHeader, PageSection, DataTable, RelatedContent, CrossLink, IndicationPageIntro, AnchorLinks, TextBlock } from '../../../components';
import { StaticImage } from 'gatsby-plugin-image';
import melAdjSafetyTable from "../../../assets/MEL-Adjuvant-006-safety-small-V1.svg"
import GlobalSafetyData, { globalSafetyBlock } from './_global_safety_data'
import { jobCodes } from '../../../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `KEYNOTE-054 and KEYNOTE-716: Selected Adverse Reactions`,
    keywords: `keytruda adjuvant treatment of melanoma adverse reactions`,
    description: `Learn about selected immune-mediated adverse reactions with KEYTRUDA® (pembrolizumab) and 
see selected adverse reactions in KEYNOTE-054 and KEYNOTE-716.`,
    schemaJsonLD: [
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/safety/adverse-reactions/melanoma-adjuvant-therapy/","@type":"MedicalWebPage","name":"Selected Adverse Reactions in KEYNOTE-054 and KEYNOTE-716","description":"In KEYNOTE-054, when KEYTRUDA was administered as a single agent to patients with stage III melanoma, KEYTRUDA was permanently discontinued due to adverse reactions in 14% of 509 patients; the most common (≥1%) were pneumonitis (1.4%), colitis (1.2%), and diarrhea (1%). Serious adverse reactions occurred in 25% of patients receiving KEYTRUDA. The most common adverse reaction (≥20%) with KEYTRUDA was diarrhea (28%). In KEYNOTE-716, when KEYTRUDA was administered as a single agent to patients with stage IIB or IIC melanoma, adverse reactions occurring in patients with stage IIB or IIC melanoma were similar to those occurring in 1011 patients with stage III melanoma from KEYNOTE-054."}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/safety/adverse-reactions/melanoma-adjuvant-therapy/","@type":"MedicalAudience","audienceType":" health care professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`
    ]
}

// image alts - so they do not repeat in mobile & desktop
const melAdjSafetyAlt = "Selected Adverse Reactions in KEYNOTE-054 and KEYNOTE-716"

//from /src/util/data/indications.js
const indicationId = 3;
const jobCode = jobCodes[2].jobCode;

const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support'
        }
    }
];

const crossLinkContent = {
    sections : [
        {
            heading: "Additional Safety Data",
            items: [
                { text: 'Advanced Melanoma', url: '/safety/adverse-reactions/melanoma-monotherapy' },
                ]
        },
        {
            heading: "Related Information",
            items: [
                { text: 'Treatment Monitoring & Management', url: '/safety/monitoring-managing-adverse-reactions/' },
                { text: 'Dose Modifications', url: '/dosing/dose-modifications/' },
                ]
        }
    ],
    interestLinks : {
        heading: "You Might Be Interested In",
        items: [
            { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
        ],
    }
};

const anchorLinkModelData = [
    {label: 'Incidence of Selected Immune-Mediated Adverse Reactions'}, 
    {label: 'Management of Selected Immune-Mediated Adverse Reactions'},
    {label: 'Selected Adverse Reactions in KEYNOTE&#8288;-&#8288;054 and KEYNOTE&#8288;-&#8288;716'},
]

const melAdjuvanDataTableFootnotes = [
    {
        label: 'c.',
        text: 'Adverse reactions occurring at same or higher incidence than in placebo arm.'
    },
    {
        label: 'd.',
        text: 'Graded per NCI-CTCAE v4.03.'
    }
]
const melAdjuvanDataTableDefinitions ="Q3W = every 3 weeks; NCI-CTCAE = National Cancer Institute-Common Terminology Criteria for Adverse Events."

const Page = ({ location }) => {

    return (
        <DefaultLayout indicationId={indicationId} jobCode={jobCode} pageMeta={ pageMeta } location={ location }>
            <div data-template-name="template-e">
                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Selected Adverse Reactions" />
                   <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId}/>
                   </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                <TemplateColumnWithSidebarContainer>
                {/* Begin Main Column */}
                    <TemplateColumn id="template-e-main">
                        <PageSection bgColor="cloud" title="Severe and Fatal Immune-Mediated Adverse Reactions">
                            <TextBlock>{globalSafetyBlock}</TextBlock>
                            <AnchorLinks items={anchorLinkModelData} />
                        </PageSection>

                        <GlobalSafetyData/>

                        <PageSection title="Selected Adverse Reactions in KEYNOTE&#8288;-&#8288;054 and KEYNOTE&#8288;-&#8288;716">
                            <TextBlock>
                                <p>In KEYNOTE&#8288;-&#8288;054, when KEYTRUDA was administered as a single agent to
                                patients with completely resected stage III melanoma, KEYTRUDA was permanently discontinued due to adverse reactions in 14% of 509 patients; the most common (≥1%) were pneumonitis (1.4%), colitis (1.2%), and diarrhea (1%). Serious adverse
                                reactions occurred in 25% of patients receiving KEYTRUDA. The most
                                common adverse reaction (≥20%) with KEYTRUDA was diarrhea (28%). In KEYNOTE&#8288;-&#8288;716, when KEYTRUDA was administered as a single agent to
                                patients with stage IIB or IIC melanoma, adverse reactions occurring in patients with stage IIB or IIC melanoma were similar to those occurring in 1,011 patients with stage III melanoma from KEYNOTE&#8288;-&#8288;054.</p>
                            </TextBlock>
                            <DataTable
                                title='Occurring in ≥10% of Patients Receiving KEYTRUDA in KEYNOTE&#8288;-&#8288;054<sup>c</sup>'
                                footnotes={melAdjuvanDataTableFootnotes}
                                definitions={melAdjuvanDataTableDefinitions}
                                mobileImage={() => <img src={melAdjSafetyTable} style={{width:'100%', maxWidth:'500px', margin: '0 auto'}} alt={melAdjSafetyAlt}/>}
                                desktopImage={() => <StaticImage quality={90} placeholder="DOMINANT_COLOR" src="../../../assets/mel-adjuvant-006-safety-large-v-1.png" alt={melAdjSafetyAlt}/>}
                            />
                        </PageSection>
                    </TemplateColumn>
                    {/* End Main Column */}

                    <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>

                </TemplateColumnWithSidebarContainer>

                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent}/>
                    </PageSection>
                </TemplateColumn>
            </div>
        </DefaultLayout>
    );
};

export default Page;
